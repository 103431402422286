import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"

import Layout from "../components/layout"
// import SEO from "../components/seo"
import { InnerHero } from "../components"
import CallToAction from "../components/CallToAction"

export const query = graphql`
  query GetSinglePortfolio($slug: String) {
    singlePortfolio: allPrismicPortfolio(
      filter: { data: { slug: { text: { eq: $slug } } } }
    ) {
      nodes {
        id
        href
        uid
        data {
          image {
            url
            alt
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
          }
          info {
            text
          }
          link {
            target
            url
          }
          list_of_each_list_of_what_we_did {
            info_of_title_of_what_we_did {
              text
            }
            list_of_title_of_what_we_did {
              text
            }
          }
          title {
            text
          }
          type {
            text
          }
          slug {
            text
          }
          technologies {
            technologies_info {
              text
            }
            technology {
              text
            }
          }
          info1 {
            text
          }
          subimage {
            alt
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
          }
          the_problem {
            text
          }
          screenshot {
            text
          }
          screenshot_image {
            alt
            fluid {
              ...GatsbyPrismicImageFluid_withWebp
            }
          }
          screenshot_info {
            text
          }
        }
      }
    }

    metadata: site {
      siteMetadata {
        author
        siteUrl
        twitterUsername
      }
    }
  }
`

const PorfolioTemplate = ({ page, data, articletitle }) => {
  const portfolios = data.singlePortfolio.nodes

  const { pathname } = useLocation()

  const { siteUrl, twitterUsername } = data.metadata.siteMetadata
  const title = `${data.singlePortfolio.nodes[0].data.title.text}`
  const image = data.singlePortfolio.nodes[0].data.image.fluid.src
  const previewDescription = `${data.singlePortfolio.nodes[0].data.info.text}`
  const author = `${data.metadata.siteMetadata.author}`
  const url = `${siteUrl}${pathname}`
  const twitterHandle = "vtwebdev"
  const canonical = pathname ? url : null

  const problemTitle = data.singlePortfolio.nodes[0].data.the_problem.text
  const problemInfo = data.singlePortfolio.nodes[0].data.info1.text

  return (
    <Layout>
      {/* <SEO
        title="Portfolio detail page"
        description="Portfolio website builds with Gatsby (static site generator), Contenful (headless CMS), CDN, and Netlify. Website optimization, image optimization, and page speed seo"
      /> */}
      <Helmet
        htmlAttributes={{ lang: "en" }}
        title={title}
        description={previewDescription}
        link={
          canonical
            ? [
                {
                  rel: "canonical",
                  href: canonical,
                },
              ]
            : []
        }
      >
        <meta name="author" content={twitterHandle} />
        <meta name="description" content={previewDescription} />
        <meta name="image" content={image} />
        {url && <meta property="og:url" content={url} />}

        {(articletitle ? true : null) && (
          <meta property="og:type" content="website" />
        )}

        {title && <meta property="og:title" content={title} />}

        {previewDescription && (
          <meta property="og:description" content={previewDescription} />
        )}

        {image && <meta property="og:image" content={image} />}

        {image && <meta property="og:image:alt" content={title} />}

        <meta name="twitter:card" content="summary_large_image" />

        <meta name="twitter:site" content={author} />

        {twitterUsername && (
          <meta name="twitter:creator" content={twitterUsername} />
        )}

        {title && <meta name="twitter:title" content={title} />}

        {previewDescription && (
          <meta name="twitter:description" content={previewDescription} />
        )}

        {image && <meta name="twitter:image" content={image} />}
      </Helmet>
      <main>
        <section id="portfolio-template">
          {portfolios &&
            portfolios.map(item => {
              const {
                title,
                list_of_each_list_of_what_we_did,
                info,
                link,
                slug,
                technologies,
                screenshot,
                screenshot_info,
                screenshot_image,
              } = item.data
              const fluid = item.data.image.fluid
              const { alt } = item.data.image.alt
              const subImage = item.data.subimage.fluid
              const subAlt = item.data.subimage.alt

              return (
                <>
                  <div className="section u-padding-bottom">
                    {!page && (
                      <>
                        <InnerHero>
                          <h1 className="heading__main-title heading__main-title--black">
                            {title.text}
                          </h1>
                        </InnerHero>
                      </>
                    )}
                  </div>

                  <Wrapper>
                    <div className="row">
                      <div className="col-1-of-1">
                        <article>
                          <Link to={`/portfolio/${slug.text}`}>
                            <figure>
                              <Image fluid={fluid} alt={alt} />
                            </figure>
                          </Link>
                        </article>
                      </div>
                    </div>

                    <div className="u-left-text">
                      <div className="row">
                        <div className="col-1-of-1">
                          <article>
                            <p className="paragraph">{info.text}</p>
                            <a
                              href={link.url}
                              target={link.target}
                              className="paragraph--link"
                            >
                              {link.url}
                            </a>
                          </article>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-2-medium-of-2">
                          <h2 className="heading-secondary heading-secondary--title u-margin-bottom-medium">
                            {problemTitle}
                          </h2>
                          <div className="col-1-of-1">
                            <article>
                              <p className="paragraph">{problemInfo}</p>
                            </article>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-2-medium-of-2">
                          <h2 className="heading-secondary heading-secondary--title u-margin-bottom-medium">
                            What I've done
                          </h2>
                          <div className="col-2-of-2">
                            {list_of_each_list_of_what_we_did &&
                              list_of_each_list_of_what_we_did.map(
                                (item, id) => {
                                  return (
                                    <article>
                                      <h2
                                        className="heading-secondary heading-secondary--title u-margin-bottom-medium"
                                        key={id}
                                      >
                                        {item.list_of_title_of_what_we_did.text}
                                      </h2>
                                      <p className="paragraph">
                                        {item.info_of_title_of_what_we_did.text}
                                      </p>
                                    </article>
                                  )
                                }
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-2-medium-of-2">
                          <h2 className="heading-secondary heading-secondary--title u-margin-bottom-medium">
                            Technologies
                          </h2>
                          <div className="col-2-of-2">
                            {technologies &&
                              technologies.map((item, id) => {
                                return (
                                  <article>
                                    <h2
                                      className="heading-secondary heading-secondary--title u-margin-bottom-medium"
                                      key={id}
                                    >
                                      {item.technology.text}
                                    </h2>
                                    <p className="paragraph">
                                      {item.technologies_info.text}
                                    </p>
                                  </article>
                                )
                              })}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-2-medium-of-2">
                          <h2 className="heading-secondary heading-secondary--title u-margin-bottom-medium">
                            {screenshot.text}
                          </h2>

                          <div className="row">
                            <div className="col-1-of-1">
                              <figure>
                                <Image
                                  fluid={screenshot_image.fluid}
                                  alt={screenshot_image.alt}
                                  fadeIn={false}
                                  loading="eager"
                                />
                              </figure>

                              <p className="paragraph">
                                {screenshot_info.text}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {subImage ? (
                        <div className="row">
                          <div className="col-1-of-1">
                            <article>
                              <figure>
                                <Image fluid={subImage} alt={subAlt} />
                              </figure>
                            </article>
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <CallToAction bigTitle="Are you ready to take your site to the next level?">
                      <h2 className="heading-primary heading-primary--main u-margin-top-small u-no-margin-bottom">
                        I create sites that rank higher in Google Search and
                        deliver faster load times. Best performance is just an
                        investment away. Contact me today.
                      </h2>
                      <button aria-label="button">
                        <Link
                          to="/contact"
                          className="btn btn--primary u-margin-top btn--animated"
                        >
                          Let's talk!
                        </Link>
                      </button>
                      <p id="text">free consultation</p>
                    </CallToAction>
                  </Wrapper>
                </>
              )
            })}
        </section>
      </main>
    </Layout>
  )
}

const Wrapper = styled.section`
  position: relative;
  /* padding-top: 5rem; */
`

export default PorfolioTemplate
